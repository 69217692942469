import { Button, Box, Grid2, TextField, Typography } from "@mui/material";
import { NewOrderProductCard } from "../components/NewOrderForm/NewOrderProductCard";
import { useEffect, useMemo, useState } from "react";
import { getAllProductsAPI } from "../api/ProductsAPI";
import { enqueueSnackbar } from "notistack";
import { ProductsListItemDefinition } from "../definitions/ProductsListItemDefinition";
import { ProductDefinition } from "../components/NewOrderForm";
import { KvkkConsent } from "../components/KvkkConsent";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";

import styles from "./WpFormPageStyles.module.css";
import { postNewOrderFromWPAPI } from "../api/OrdersAPI";
import { Formik, Form, FormikHelpers, FieldArray } from "formik";
import { TagManager } from "../components/TagManager";
import { PatternFormat } from "react-number-format";

declare global {
  interface Window {
    grecaptcha: any;
  }
}

interface FormValues {
  connectionFullName: string;
  connectionEmail: string;
  connectionPhoneNumber: string;
  products: (ProductDefinition & { uid: string })[];
}

export const WpFormPage = () => {
  const [availableProducts, setAvailableProducts] = useState<
    ProductsListItemDefinition[]
  >([]);

  const [kvkkConsent, setKvkkConsent] = useState(false);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);

  const handleCaptchaChange = (token: string | null) => {
    setCaptchaToken(token);
  };

  const resetCaptcha = () => {
    setCaptchaToken(null);
    if (window.grecaptcha) {
      window.grecaptcha.reset();
    }
  };

  const onSubmitHandler = (
    values: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    if (
      values.products.length === 0 ||
      values.products.some((p) => !p.productId)
    ) {
      actions.setSubmitting(false);
      enqueueSnackbar("Siparişinizi kontrol ediniz!", { variant: "warning" });
      return;
    }

    // TODO - Add validation for empty/invalid fields
    postNewOrderFromWPAPI({
      connectionFullName: values.connectionFullName,
      connectionEmail: values.connectionEmail,
      connectionPhoneNumber: values.connectionPhoneNumber,
      products: values.products.map((product) => ({
        id: Number(product.productId),
        name:
          availableProducts.find(
            (p) => p.product_id === Number(product.productId)
          )?.product_name || "",
        quantity: Number(product.quantity),
        additionalDetails: product.additionalDetails,
        properties: product.properties,
      })),
      captchaToken,
    })
      .then(() => {
        actions.resetForm();
        resetCaptcha();
        enqueueSnackbar("Siparişiniz başarıyla oluşturuldu!", {
          variant: "success",
        });
      })
      .catch((error) => {
        console.error(error);
        actions.setSubmitting(false);
        enqueueSnackbar(error?.response?.data?.error || error?.message, {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    getAllProductsAPI()
      .then(({ data }) => {
        setAvailableProducts(data.products);
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar("Ürünler gösterilirken bir hata oluştu.", {
          variant: "error",
        });
      });
  }, []);

  const initialValues = useMemo(
    () => ({
      connectionFullName: "",
      connectionEmail: "",
      connectionPhoneNumber: "",
      products: [
        {
          productId: "",
          quantity: "",
          additionalDetails: "",
          properties: {},
          uid: String(Date.now()),
        },
      ],
    }),
    []
  );

  const validationSchema = Yup.object().shape({
    connectionFullName: Yup.string().required(),
    connectionEmail: Yup.string()
      .email("Geçerli bir email adresi giriniz")
      .required("Email adresi alanı zorunludur"),
    connectionPhoneNumber: Yup.string()
      .required("Telefon numarası alanı zorunludur")
      .matches(
        /^(((\+|00)?(90)|0)[-| ]?)?((5\d{2})[-| ]?(\d{3})[-| ]?(\d{2})[-| ]?(\d{2}))$/gm,
        "Geçerli bir telefon numarası giriniz"
      ),
  });

  return (
    <>
      <TagManager />
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitHandler}
        validationSchema={validationSchema}
        validateOnChange
      >
        {({
          values,
          handleBlur,
          handleChange,
          isSubmitting,
          isValid,
          errors,
          touched,
        }) => (
          <div className={styles.Wrapper}>
            <Form className={styles.Form}>
              <Grid2 container direction="column" spacing={1}>
                <Typography align="center" variant="h5">
                  Teklif almak için formu doldurup siparişinizi{" "}
                  <strong>hemen verin</strong>
                </Typography>
                <TextField
                  fullWidth
                  label="Bağlantı Adı Soyadı"
                  name="connectionFullName"
                  onChange={handleChange}
                  required
                  value={values.connectionFullName}
                />
                <TextField
                  fullWidth
                  label="E-posta"
                  name="connectionEmail"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  type="email"
                  value={values.connectionEmail}
                  error={touched.connectionEmail && !!errors.connectionEmail}
                  helperText={touched.connectionEmail && errors.connectionEmail}
                />
                <PatternFormat
                  format="### ### ## ##"
                  customInput={TextField}
                  fullWidth
                  label="Telefon"
                  name="connectionPhoneNumber"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  type="tel"
                  value={values.connectionPhoneNumber}
                  error={
                    touched.connectionPhoneNumber &&
                    !!errors.connectionPhoneNumber
                  }
                  helperText={
                    touched.connectionPhoneNumber &&
                    errors.connectionPhoneNumber
                  }
                  allowEmptyFormatting
                  mask="_"
                />
                <FieldArray name="products">
                  {({ remove, push }) => (
                    <>
                      {values.products.map((product, index) => (
                        <NewOrderProductCard
                          key={`NewOrderProductCard_${index}_${product.uid}`}
                          availableProducts={availableProducts}
                          index={index}
                          onProductRemove={() => remove(index)}
                          product={product}
                        />
                      ))}
                      <Button
                        variant="contained"
                        onClick={() =>
                          push({
                            productId: "",
                            quantity: "",
                            additionalDetails: "",
                            properties: {},
                            uid: Date.now(),
                          })
                        }
                      >
                        Ürün ekle
                      </Button>
                    </>
                  )}
                </FieldArray>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting || !isValid}
                >
                  Gönder
                </Button>
                <KvkkConsent
                  checked={kvkkConsent}
                  onChange={(checked) => {
                    setKvkkConsent(checked);
                  }}
                />
                <Box sx={{ my: 2, display: "flex", justifyContent: "center" }}>
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_CAPTCHA_KEY || ""} // Google reCAPTCHA site anahtarınızı buraya ekleyin
                    onChange={handleCaptchaChange}
                    onExpired={resetCaptcha}
                  />
                </Box>
              </Grid2>
            </Form>
          </div>
        )}
      </Formik>
    </>
  );
};
